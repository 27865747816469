import {Box, IconButton, Stack, TextField, Typography} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import theme from "./theme";
import {useState} from "react";
import {verifyPassword} from "./api";
import {useNavigate} from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const portrait = window.screen.height > window.screen.width;
  const [passwordText, setPasswordText] = useState(null);
  const [passwordDisabled, setPasswordDisabled] = useState(false);
  const [passwordError, setPasswordError] = useState(null);

  const onSubmitPass = async () => {
    setPasswordDisabled(true)
    if (await verifyPassword(passwordText)) {
      navigate("/locker", {
        state: {
          password: passwordText.substring(0, passwordText.length - 4)
        }
    })
    } else {
      setPasswordError("Invalid password");
    }
    setPasswordDisabled(false)
  }

  return (
    <Box
        display={"flex"}
        height={"100vh"}
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={theme.primary}
    >
      <Stack
        width={portrait ? "100vw" : "50vw"}
        spacing={2}
        p={4}
        borderRadius={portrait? "0px" : "10px"}
        bgcolor={theme.secondary}
      >
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Typography variant="h5" color={theme.primaryDark}>
            Yes, ma'am
          </Typography>
          <Stack width={"56px"} justifyContent={"center"} alignItems={"center"}>
            <IconButton
              sx={{
                background: theme.primaryDark,
                color: theme.secondary,
                ":hover": {
                  background: theme.primary
                }
              }}
              disabled={passwordDisabled}
              onClick={onSubmitPass}
            >
              <ArrowForwardIcon lightingColor={theme.primaryDark}/>
            </IconButton>
          </Stack>
        </Stack>
        <Stack flexDirection={"row"} alignItems={"center"}>
          <TextField
              id="pass"
              label="Password"
              variant="filled"
              sx={{flex: 1}}
              color={theme.primaryDark}
              disabled={passwordDisabled}
              value={passwordText}
              error={!!passwordError}
              helperText={passwordError}
              onChange={event => setPasswordText(event.target.value)}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default App;
