import {Grid, IconButton, Stack, Typography} from "@mui/material";
import theme from "./theme";
import {useState} from "react";
import {getDoc, listDocs} from "./api";
import {useLocation} from "react-router-dom";
import ArticleIcon from '@mui/icons-material/Article';

function Locker() {
  const portrait = window.screen.height > window.screen.width;
  const {state} = useLocation();
  const {password} = state;
  const [docs, setDocs] = useState([])

  useState(async () => {
    const docsList = await listDocs(password);
    setDocs(docsList);
  });

  return (
    <Stack height={"100vh"}>
      <Stack justifyContent={"center"} alignItems={"center"} bgcolor={theme.primaryDark} height={"64px"}>
        <Typography variant={"h4"} color={theme.secondary}>Inventory</Typography>
      </Stack>
      <Stack>
        <Grid container spacing={8} p={8}>
          {docs.map(doc => {
            return(
              <Grid item>
                <Stack justifyContent={"center"} alignItems={"center"} width={"min(20vh, 20vw)"} height={"min(20vh, 20vw)"}>
                  <IconButton
                    sx={{
                      width: "80%",
                      height: "80%",
                      background: theme.secondary,
                      color: theme.primaryDark,
                      ":hover": {
                        background: theme.primaryLight
                      }
                    }}
                    onClick={() => getDoc(password, doc)}
                  >
                    <ArticleIcon lightingColor={theme.primaryDark} sx={{
                      width: "50%",
                      height: "50%",
                    }}/>
                  </IconButton>
                  <Stack justifyContent={"center"} alignItems={"center"} textAlign={"center"} p={1}>
                    <Typography fontSize={portrait ? "12px" : "18px"} color={theme.primaryDark}>{doc}</Typography>
                  </Stack>
                </Stack>
              </Grid>
            )
          })}
        </Grid>
      </Stack>
    </Stack>
  )
}

export default Locker;