import axios from "axios";


const BASE_URL = "https://docserver-731956839532.us-central1.run.app";

const verifyPassword = async (password) => {
  try {
    const response = await axios.post(BASE_URL + "/verify?password=" + password);
    return response.status === 200
  } catch (e) {
    return false
  }
}

const listDocs = async (password) => {
  const response = await axios.get(BASE_URL + "/docs/list?password=" + password);
  if (response.status === 200) {
    return response.data
  } else {
    return null
  }
}

const getDoc = async (password, docName) => {
  const response = await axios.get(
    BASE_URL + `/doc/${docName}?password=` + password,
    {
      responseType: "blob"
    }
  );
  if (response.status === 200) {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', docName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
}

export {
  verifyPassword,
  listDocs,
  getDoc
}